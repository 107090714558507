<template>
  <main
    id="fallback-page"
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-center"
  >
    <div id="kk-logo" class="w-75"></div>
    <div class="w-75 badge-col d-flex flex-column align-items-center">
      <a
        class="store-link mb-3"
        href="https://play.google.com/store/apps/details?id=com.szentertainment.kleinekrone"
      >
        <img
          v-if="$store.state.locale === 'de'"
          draggable="false"
          alt="Jetzt bei Google Play"
          class="store-logo"
          src="@/assets/img/buttons/google-play-de.png"
        />
        <img
          v-else
          draggable="false"
          alt="Get it on Google Play"
          class="store-logo"
          src="@/assets/img/buttons/google-play-en.png"
        />
      </a>
      <a class="store-link" href="http://itunes.apple.com/app/id1471196187">
        <img
          v-if="$store.state.locale === 'de'"
          draggable="false"
          alt="Laden im App Store"
          class="store-logo"
          src="@/assets/img/buttons/app-store-de.svg"
        />
        <img
          v-else
          draggable="false"
          alt="Download on the App Store"
          class="store-logo"
          src="@/assets/img/buttons/app-store-en.svg"
        />
      </a>
    </div>
  </main>
</template>

<script>
export default {
  name: "FallBackPage",
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {
    this.winWidth();
    this.$store.commit("setIsPageLoading", false);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    winWidth: function () {
      this.intervalId = setInterval(() => {
        let w = window.innerWidth;
        if (w >= 1024) {
          this.$router.go(-1);
        } else return;
      }, 100);
    },
  },
};
</script>
<style scoped lang="scss">
$logo-width: 649px;
#fallback-page {
  #kk-logo {
    background: url("@/assets/img/logo/full_logo-short.png") center/contain
      no-repeat;
    min-height: 300px;
  }

  .store-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px; /* Set a consistent height */
  }

  .store-logo {
    height: 100%; /* Match the parent's height */
    width: auto; /* Adjust width to maintain aspect ratio */
    object-fit: contain; /* Ensure content fits inside the container */
  }
}
</style>
