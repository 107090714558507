import * as eventAPI from "@/API/events.js";
import dateFunctions from "@/mixins/dateFunctions.js";

const getDefaultState = () => {
  return {
    activeEventOffer: null,
    allEvents: [],
    specialEvents: [],
    regularEvents: [],
    userEvents: [],
    eventWinnerLastShown: null,
    showEventWinnerCounter: 0,
    paymentEventRaffle: null,
    paymentEventUserRaffles: [],
    paymentEventUserRaffle: null,
    showFootballResultForEvent: null,
    animatedBadges: [],
    raffleCollectedForEvent: null,
    tutorialShownForEvents: [],
    activePasses: [],
    currentLevelIndex: 0,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAllEvents: (state, array) => (state.allEvents = array),
  setActivePasses: (state, array) => (state.activePasses = array),
  setActiveEventOffer: (state, offer) => (state.activeEventOffer = offer),
  setUserEvent: (state, event) => {
    let eventToUpdate = state.allEvents.find(
      (ev) => ev.id === event.ingame_event_id
    );
    eventToUpdate.userEvent = event;
    const userEventExistsAtIndex = state.userEvents.findIndex(
      (ev) => ev.id === event.id
    );
    if (userEventExistsAtIndex !== -1) {
      state.userEvents[userEventExistsAtIndex] = event;
    } else {
      state.userEvents.push(event);
    }
    state.userEvents = [...state.userEvents];
  },
  resetIngameEvents: (state) => {
    state.allEvents = [];
    state.specialEvents = [];
    state.regularEvents = [];
    state.userEvents = [];
    state.showFootballResultForEvent = null;
    state.paymentEventRaffle = null;
    state.paymentEventUserRaffle = null;
  },
  setShowEventWinner: (state, date) => (state.eventWinnerLastShown = date),
  increaseEventWinnerCounter: (state) => (state.showEventWinnerCounter += 1),
  setPaymentEventRaffle: (state, obj) => (state.paymentEventRaffle = obj),
  setPaymentEventUserRaffles: (state, obj) =>
    state.paymentEventUserRaffles.push(obj),
  setPaymentEventUserRaffle: (state, obj) =>
    (state.paymentEventUserRaffle = obj),
  clearPaymentEventUserRaffles: (state) => (state.paymentEventUserRaffles = []),
  setShowFootballResult: (state, id) => (state.showFootballResultForEvent = id),
  addEventWinner: (state, log) => {
    let eventToUpdate = state.allEvents.find(
      (ev) => ev.id === log[0].raffle.raffleable_id
    );
    eventToUpdate.finalWinners = log;
    state.allEvents = [...state.allEvents];
  },
  addSpecialEvent: (state, obj) => state.specialEvents.push(obj),
  addRegularEvent: (state, obj) => state.regularEvents.push(obj),
  addUserEvent: (state, obj) => state.userEvents.push(obj),
  setBadgeAnimation: (state, obj) => {
    const shownEventExistsAtIndex = state.animatedBadges.findIndex(
      (ev) => ev.id === obj.id
    );
    if (shownEventExistsAtIndex !== -1) {
      state.animatedBadges[shownEventExistsAtIndex] = obj;
    } else {
      state.animatedBadges.push(obj);
    }
    state.animatedBadges = [...state.animatedBadges];
  },
  markRaffleAsCollected: (state, int) => (state.raffleCollectedForEvent = int),
  setCurrentLevelIndex: (state, int) => (state.currentLevelIndex = int),
  markEventTutorialAsShown: (state, id) =>
    state.tutorialShownForEvents.push(id),
};

const getters = {
  getShowEventWinnerCounter: (state) => state.showEventWinnerCounter,

  getInactiveRegularEvents: (state) =>
    state.regularEvents.filter((event) => !event.isActive),
  getPaymentEventDays: (state) => {
    let eventDays;
    if (
      state.allEvents.filter((event) =>
        event.type.endsWith("PaymentIngameEventType")
      ).length > 0
    ) {
      eventDays = state.allEvents.filter((event) =>
        event.type.endsWith("PaymentIngameEventType")
      );
    } else {
      eventDays = [];
    }
    return eventDays;
  },
  getFootballEventDays: (state) => {
    let eventDays;
    if (
      state.allEvents.filter((event) =>
        event.type.endsWith("SportBetIngameEventType")
      ).length > 0
    ) {
      eventDays = state.allEvents.filter((event) =>
        event.type.endsWith("SportBetIngameEventType")
      );
    } else {
      eventDays = [];
    }
    return eventDays;
  },
  getUserHasTipped: (state, getters) => {
    if (!state.userEvents) return false;
    return (
      state.userEvents.findIndex(
        (event) =>
          event && event.ingame_event_id === getters.getFootballEventData.id
      ) >= 0
    );
  },
  getUserHasTippedCorrectly: (state, getters) => {
    if (
      getters.getUserHasTipped &&
      state.paymentEventUserRaffle &&
      state.paymentEventUserRaffle.log
    ) {
      return true;
    } else {
      return false;
    }
  },
  getIsDraw: (state) => {
    if (!state.paymentEventRaffle) return false;
    return (
      state.paymentEventRaffle.result_away ===
      state.paymentEventRaffle.result_home
    );
  },
  getHomeWins: (state) => {
    if (!state.paymentEventRaffle) return false;
    return (
      state.paymentEventRaffle.result_home >
      state.paymentEventRaffle.result_away
    );
  },
  getActualResult: (state, getters) => {
    if (!state.paymentEventRaffle) {
      return "";
    } else {
      if (getters.getIsDraw) {
        return "draw";
      } else if (getters.getHomeWins) {
        return state.paymentEventRaffle.team_home;
      } else {
        return state.paymentEventRaffle.team_away;
      }
    }
  },
  getUserTip: (state, getters) => {
    if (
      !getters.getFootballEventData ||
      !getters.getFootballEventData.userEvent
    ) {
      return "";
    } else {
      let betData = getters.getFootballEventData.userEvent;
      let tip = betData.payload;
      if (tip.away === tip.home) {
        return "draw";
      } else if (tip.home > tip.away) {
        return state.paymentEventRaffle.team_home;
      } else {
        return state.paymentEventRaffle.team_away;
      }
    }
  },
  getNextFootballEvent: (state, getters) => {
    // it is assumed that only a maximum of 2 football events will be visible at the same time
    if (getters.getFootballEventDays.length <= 1) return null;
    else {
      return getters.getFootballEventDays[1];
    }
  },
  getFootballEventData: (state, getters) => {
    if (getters.getFootballEventDays[0]) {
      return getters.getFootballEventDays[0];
    } else return null;
  },
  shownEventBadges: (state, getters) => {
    let regularEventsToDisplay = state.regularEvents.filter(
      (event) => event.isActive
    );

    // Conditions for special event badge to be visible:
    // * Event is active
    // * Event is finished but user has participated
    // * Event is finished, user has not participated, but raffle result is available

    let specialEventsToDisplay = state.specialEvents.filter((event) => {
      if (event.isActive) return true;
      else if (
        state.userEvents.findIndex((ev) => {
          return (
            ev.ingame_event_id === event.id &&
            event.id === getters.getFootballEventData.id
          );
        }) >= 0
      ) {
        return true;
      } else {
        return (
          state.paymentEventRaffle &&
          state.paymentEventRaffle.raffleable_id === event.id
        );
      }
    });
    let activeKronenPassesToDisplay = state.activePasses;

    return regularEventsToDisplay
      .concat(specialEventsToDisplay)
      .concat(activeKronenPassesToDisplay);
  },
  getAnimatedBadges: (state) => state.animatedBadges,
  getRaffleCollected: (state) => state.raffleCollectedForEvent,
  getIsCurrentPassLevelLocked: (state) => {
    if (!state.activePasses[0]) return false;
    const currentLevelData =
      state.activePasses[0].levels[state.currentLevelIndex + 1];
    if (
      currentLevelData &&
      (currentLevelData.crowns ||
        currentLevelData.gold ||
        currentLevelData.payment_offer_id) &&
      !dateFunctions.methods.isDateInPast(
        new Date(currentLevelData.start_at),
        dateFunctions.methods.getCurrentDate()
      )
    ) {
      return true;
    } else {
      return false;
    }
  },
  getShowTutorialForEvent: (state) => (id) => {
    if (state.tutorialShownForEvents.length === 0) {
      return true;
    }
    return !state.tutorialShownForEvents.includes(id);
  },
};

const actions = {
  getEvents({ commit }) {
    commit("resetIngameEvents");
    return new Promise((resolve, reject) => {
      eventAPI
        .getAllEvents()
        .then((result) => {
          let events = result.data.data.events;

          if (events.length <= 0) {
            //currently no events
            commit("popups/setNextPipelineQueueStep", null, {
              root: true,
            });
            return;
          }
          events.forEach((event) => {
            if (
              dateFunctions.methods.isDateInPast(
                new Date(event.end_at),
                new Date()
              ) ||
              dateFunctions.methods.isDateInFuture(
                new Date(event.start_at),
                new Date()
              )
            ) {
              event.isActive = false;
            } else {
              event.isActive = true;
            }

            if (
              event.type.endsWith("PaymentIngameEventType") ||
              event.type.endsWith("SportBetIngameEventType")
            ) {
              commit("addSpecialEvent", event);
            } else {
              commit("addRegularEvent", event);
            }
          });
          commit("setAllEvents", events);
          resolve(events);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  displayRaffleWinner({ commit }, eventId) {
    // finished event with winners
    return new Promise((resolve, reject) => {
      eventAPI
        .getLoggedRaffleById(eventId)
        .then((res) => {
          if (res.data.data.log.length <= 0) {
            commit("setShowEventWinner", null);
          } else {
            commit("addEventWinner", res.data.data.log);
          }
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  updateUserEvent({ commit }, eventId) {
    return new Promise((resolve, reject) => {
      eventAPI
        .getEventForCurrentUser(eventId)
        .then((result) => {
          let event = result.data.data.event;
          if (event) {
            commit("setUserEvent", event);
          }
          resolve(event);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  getOlympiaEventRaffle({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      eventAPI
        .getGoForGoldRaffle()
        .then((res) => {
          // only fetch info for active raffles
          let raffles = res.data.data.raffles.filter(
            (raffle) =>
              !dateFunctions.methods.isDateInPast(
                new Date(raffle.payload.hidden_at),
                new Date()
              )
          );
          console.log(raffles);
          if (!raffles) return;
          commit("setOlympiaEventRaffles", raffles);
          raffles.forEach((raffle) => {
            eventAPI
              .getTipKingEventRaffleByIdAndUser(
                raffle.id,
                rootGetters["user/currentUser"].id
              )
              .then((res) => {
                let log = res.data.data.log;
                if (!log) return;
                commit("setPaymentEventUserRaffles", log);
                resolve(res);
              })
              .catch((err) => console.log(err));
          });

          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getFootballEventRaffle({ commit, dispatch }, relevantEvent) {
    eventAPI
      .getTipKingEventRaffle()
      .then((res) => {
        let raffles = res.data.data.raffles;
        let relevantRaffle = raffles.find(
          (raffle) => raffle.raffleable_id === relevantEvent.id
        );
        if (!relevantRaffle) {
          return;
        } else {
          // only display football results if they have been raffled already
          commit("setShowFootballResult", relevantEvent.id);
          commit("setPaymentEventRaffle", relevantRaffle);
          dispatch("getPaymentUserRaffle", relevantRaffle.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getPaymentUserRaffle({ commit, rootGetters }, raffleId) {
    eventAPI
      .getTipKingEventRaffleByIdAndUser(
        raffleId,
        rootGetters["user/currentUser"].id
      )
      .then((res) => {
        let log = res.data.data.log;
        if (!log) return;
        commit("setPaymentEventUserRaffle", log);
      })
      .catch((err) => console.log(err));
  },
  checkForPasses({ commit }) {
    return new Promise((resolve, reject) => {
      eventAPI
        .getKronenpassActive()
        .then((res) => {
          commit("setActivePasses", res.passes);
          resolve(res);

          return res;
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  resetEventsState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
