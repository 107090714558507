<template>
  <div id="lobby-page">
    <div id="lobby-content-container" class="container-fluid px-0">
      <!-- <button
        class="button-blue-modern"
        @click="$store.dispatch('gameplay/fetchLobbyLayout')"
      >
        Refresh Lobby Layout
      </button> -->
      <SlotSorting
        v-if="!isLoading"
        :results="shownGames.length"
        :grid-view="gridView"
        :selected-row="selectedRow"
        @search="handleSearch"
        @start="handleBackToStart"
      />
      <template v-if="!gridView">
        <template v-if="!isLoading">
          <game-row
            v-for="(row, index) in shownRows"
            :key="row.id"
            :row-data="row"
            :kingspath-on-game="kingspathOnGame"
            :active-tournament-game-ids="activeTournamentGameIds"
            :upcoming-tournament-game-ids="upcomingTournamentGameIds"
            :row-index="index"
          >
          </game-row>
        </template>
        <template v-else>
          <game-row v-for="(n, index) in 3" :key="index"></game-row>
        </template>
      </template>
      <template v-else>
        <game-grid
          :games="shownGames"
          :kingspath-on-game="kingspathOnGame"
          :active-tournament-game-ids="activeTournamentGameIds"
          :upcoming-tournament-game-ids="upcomingTournamentGameIds"
          :grid-title="gridTitle"
        >
        </game-grid>
      </template>

      <b-row id="legal-container" class="mt-5" no-gutters>
        <b-row class="w-75 mx-auto" align-v="center">
          <b-col class="text-center"
            ><img
              draggable="false"
              src="@/assets/img/logo/logo-kleinekrone.svg"
          /></b-col>
          <b-col class="text-center">
            <router-link to="/tac" class="legal-link"
              >{{ $t("legal.tac") }}
            </router-link>
          </b-col>
          <b-col class="text-center">
            <router-link to="/privacy" class="legal-link"
              >{{ $t("legal.privacy") }}
            </router-link>
          </b-col>
          <b-col class="text-center">
            <router-link to="/imprint" class="legal-link"
              >{{ $t("legal.imprint") }}
            </router-link>
          </b-col>
          <b-col @click="$bvModal.show('cookie-modal')">
            <router-link to="#" class="legal-link"
              >{{ $t("legal.cookie_settings") }}
            </router-link>
          </b-col>
          <b-col cols="12" class="text-center mt-3"
            ><span class="legal-text"
              >© Glücksfaktor 7 GmbH {{ currentYear }}<br />
              (Version {{ $store.getters.getAppVersion }})</span
            ></b-col
          >
          <b-col cols="12" class="text-center"
            ><p class="legal-text mt-3">
              {{ $t("legal.disclaimer") }}
            </p></b-col
          >
          <CookieModal />
        </b-row>
      </b-row>
    </div>
  </div>
</template>

<script>
import SlotSorting from "@/components/lobby/slot-sorting.vue";
import offerModal from "@/mixins/offerModal.js";
import CookieModal from "@/components/legal/cookie-modal.vue";
import GameRow from "@/components/lobby/game-row.vue";
import GameGrid from "@/components/lobby/game-grid.vue";
import sortingMethods from "@/mixins/sortingMethods.js";
import { getGamesByTag, getGamesForRow } from "@/API/games.js";

export default {
  name: "LobbyPage",
  components: {
    SlotSorting,
    CookieModal,
    GameRow,
    GameGrid,
  },
  mixins: [offerModal, sortingMethods],
  data() {
    return {
      shownGames: [],
      error: false,
      gridView: false,
      shownRows: [],
      gridTitle: "",
      allRows: [],
      selectedRow: {},
      activeTournamentGameIds: null,
      upcomingTournamentGameIds: null,
    };
  },

  computed: {
    routeParams() {
      return this.$route.params;
    },
    isLoading() {
      return this.$store.getters.getIsPageLoading;
    },
    allGames() {
      return this.$store.state.gameplay.games;
    },
    allAvailableRows() {
      return this.$store.getters["gameplay/getAllLobbyRows"];
    },

    kingspathOnGame() {
      return "";
      // TODO: add updated property
    },

    isCookieComfort: function () {
      return this.$store.getters["cookieManager/isTypeAccepted"]("comfort");
    },
    currentYear() {
      let today = new Date();
      return today.getFullYear();
    },
  },
  watch: {
    routeParams() {
      if (!this.routeParams || !this.routeParams.gameSelection) {
        this.gridView = false;
        this.shownRows = this.allRows;
      } else {
        this.setUpGameGrid();
      }
    },
    allAvailableRows() {
      this.allRows = this.$store.getters["gameplay/getAllLobbyRows"];
      this.shownRows = this.allRows;
      this.getTournamentGameIds();
    },
    currentYear() {
      let today = new Date();
      return today.getFullYear();
    },

    $route(to) {
      if (to.name === "ProviderSlots") {
        this.fetchData();
      }
    },
  },

  created() {
    this.userId = this.$store.getters["user/currentUser"].id;
    this.$root.$on("go-back-home", () => (this.gridView = false));

    if (this.isCookieComfort) {
      this.$store.dispatch("cookieManager/setCookie", {
        name: "welcome",
        data: this.userId,
      });
    } else {
      if (this.$store.getters["cookieManager/isCookieSet"]("welcome")) {
        this.$store.dispatch("cookieManager/removeCookie", "welcome");
      }
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store.commit("setIsPageLoading", true);

      if (this.$store.getters["gameplay/lobbyUpdateNeeded"]) {
        Promise.all([
          this.$store.dispatch("gameplay/fetchLobbyLayout"),
          this.$store.dispatch("gameplay/updateGameList"),
          this.$store.dispatch("gameplay/fetchJackpotData"),
          this.$store.dispatch("getGeneralConfig"),
        ])
          .then(() => {
            this.setGameData();
          })
          .catch((e) => console.log(e));
      } else {
        this.setGameData();
      }
      if (
        this.routeParams &&
        (this.routeParams.gameSelection || this.routeParams.providerId)
      ) {
        this.setUpGameGrid();
      }
    },

    setGameData() {
      this.allRows = this.$store.getters["gameplay/getAllLobbyRows"];
      if (this.allRows.length === 0) {
        this.gridView = true;
        this.shownGames = this.allGames;
      } else {
        this.shownRows = this.allRows;
        this.shownGames = this.allGames;
      }

      this.$store.commit("setIsPageLoading", false);

      this.getTournamentGameIds();
    },
    setUpGameGrid() {
      this.gridView = true;
      let foundRow;
      if (this.routeParams.gameSelection === "all") {
        this.displayAllGames();
      } else if (this.routeParams.providerId) {
        this.displayAllGamesByProvider();
      } else if (this.routeParams.gameSelection.startsWith("tag-")) {
        this.findTaggedGames();
      } else {
        foundRow = this.allRows.find(
          (row) => row.slug === this.routeParams.gameSelection
        );

        if (!foundRow) {
          this.$router.push("/");
        } else {
          this.selectRowToDisplay(foundRow);
        }
      }

      this.$store.commit("setIsPageLoading", false);
    },
    displayAllGames() {
      this.selectedRow = {};
      this.gridTitle = this.$t("sorting.all_games");
      this.shownGames = this.allGames;
    },

    displayAllGamesByProvider() {
      const id = this.routeParams.providerId;
      this.selectedRow = {};
      this.gridTitle = this.$t("sorting.all_games");
      this.shownGames = this.allGames.filter((game) => game.publisher_id == id);
    },
    selectRowToDisplay(row) {
      this.selectedRow = row;
      getGamesForRow(row.slug)
        .then((res) => {
          this.shownGames = res.data.data.model.games;
        })
        .catch((e) => console.log(e));
      switch (row.type) {
        case "PUBLISHER_GAMES":
          this.gridTitle = row.details.publisher.name;
          break;
        case "TAG_GAMES":
          this.gridTitle = row.name;
          break;

        default:
          this.gridTitle = this.$t(`lobby.layout_model_type.${row.type}`);
      }
    },
    findTaggedGames() {
      let tagId = parseInt(this.routeParams.gameSelection.split("-")[1]);
      getGamesByTag(tagId).then((res) => {
        this.shownGames = res.data.data.games;
        let gameTags = res.data.data.games[0].game_tags;
        let relevantTag = gameTags.find((tag) => tag.id === tagId);
        this.gridTitle = relevantTag.title;
      });
    },

    handleSearch(term) {
      this.filterSlotsBySearchTerm(term);
      this.gridView = true;
      if (this.shownGames.length > 0) {
        this.gridTitle = this.$t("sorting.search_results", { 0: term });
      } else {
        this.gridTitle = "";
      }
    },

    handleBackToStart() {
      this.gridView = false;
      this.shownRows = this.allRows;
      this.selectedRow = {};
      if (this.routeParams.gameSelection) {
        this.$router.push("/");
      }
    },

    getTournamentGameIds() {
      if (!this.allRows.length) {
        return;
      }
      let active = [];
      let upcoming = [];

      let tournamentRow = this.allRows.find(
        (row) =>
          row.type === "TOURNAMENT_GAMES" ||
          row.type === "TOURNAMENT_SOON_GAMES"
      );

      if (tournamentRow) {
        let games = tournamentRow.details.games;
        for (let i = 0; i < games.length; i++) {
          if (games[i].tournament && games[i].tournament_active) {
            active.push(games[i].id);
          } else if (games[i].tournament && !games[i].tournament_active) {
            upcoming.push(games[i].id);
          }
        }
      }

      this.activeTournamentGameIds = active;
      this.upcomingTournamentGameIds = upcoming;
    },
  },
};
</script>
<style lang="scss">
#lobby-page {
  min-height: 100vh;

  #lobby-content-container {
    position: relative;
    max-width: 1280px;
    padding-left: 25px;
    padding-right: 25px;

    .slot-container {
      height: 100%;

      .game-prev-col {
        padding: 5px;
      }
    }

    #activeSlot {
      width: 80%;
    }

    .closeButton {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;

      &:hover {
        filter: drop-shadow(0 0 16px $light-blue);
        cursor: pointer;
      }
    }

    .spinner-border {
      color: $light-blue;
    }

    #slot-container {
      max-width: 95%;
    }
  }

  .game-row-title {
    z-index: 15;

    span:first-of-type {
      font-size: 32px;
      text-shadow: 0 2px 4px black;
    }
  }

  .legal-link {
    font-size: 18px;
    color: #c8c8c8 !important;
    &:hover {
      text-decoration: none;
      color: $light-blue !important;
    }
  }

  .legal-text {
    font-size: 13px;
    color: #718096;
    text-transform: uppercase;
  }
}
</style>
