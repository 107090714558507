export const seoRoutes = [
  {
    path: "/slot-spiele",
    name: "slot-spiele",
    meta: {
      seoTitle: "Slot Spiele",
      seoDescription:
        "Erlebe den Nervenkitzel und das Spielvergnügen von Slotspielen rund um die Uhr und überall mit Meine Krone. Auf unserer Plattform erwarten Dich Hunderte Spielautomaten, auf die Du 24/7 und von jedem Gerät aus zugreifen kannst.",
    },
  },
  {
    path: "/android-spiele",
    name: "android-spiele",
    meta: {
      seoTitle: "Android Spiele",
      seoDescription:
        "Entdecke bei Meine Krone die besten und angesagtesten Android-Spiele und verwandle Dein Smartphone oder Tablet in ein tragbares Casino der Extraklasse. Wir bieten Dir die ultimative Herausforderung mit abwechslungsreichen Spielautomaten, Features und Jackpots, die den Nervenkitzel auch ohne Echtgeld garantieren. Registriere Dich bei uns kostenlos und erhalte Deinen Willkommensbonus, damit Du gleich loslegen kannst.",
    },
  },
  {
    path: "/black-jack-spiele",
    name: "black-jack-spiele",
    meta: {
      seoTitle: "Black Jack Spiele",
      seoDescription:
        "Bei Casino und Las Vegas denken viele sofort an Black Jack. Denn der Klassiker unter den Kartenspielen macht besonders viel Spaß und ist dabei relativ leicht zu lernen. Ziel des Spiels ist es, das Blatt des Gebers zu überbieten, ohne dabei die Punktzahl von 21 zu überschreiten. Um Dich mit den Spielregeln und Strategien vertraut zu machen, ist es ein guter Einstieg, Black Jack kostenlos zu spielen. Bei Meine Krone hast Du Dein persönliches Gratis-Casino zuhause und unterwegs stets dabei und kannst so rund um die Uhr Deiner Leidenschaft nachgehen.",
    },
  },

  {
    path: "/roulette-spiele",
    name: "roulette-spiele",
    meta: {
      seoTitle: "Roulette Spiele",
      seoDescription:
        "Denkst Du an schillernde Casinos von Las Vegas bis Monte Carlo, kommt Dir sicher der Klassiker Roulette in den Sinn. Denn neben Slots und Automaten hat sich das Roulettespiel zu einem der weltweit bekanntesten Glücksspiele entwickelt. Im Internet Roulette kostenlos zu spielen, gibt Dir die Möglichkeit etwas von dieser besonderen Casino-Atmosphäre einzufangen, und das ohne den Einsatz von Echtgeld. Setze etwas Spielgeld auf Deine Glückszahl und beobachte, wie die Kugel sich im Roulettekessel dreht, bis sie schließlich zum Liegen kommt.",
    },
  },

  {
    path: "/gluecksrad-spiele",
    name: "gluecksrad-spiele",
    meta: {
      seoTitle: "Glücksrad Spiele",
      seoDescription: "",
    },
  },

  {
    path: "/freispiele-ohne-einzahlung",
    name: "freispiele-ohne-einzahlung",
    meta: {
      seoTitle: "Freispiele ohne Einzahlung",
      seoDescription:
        "In der fesselnden Atmosphäre unseres Online-Casinos bereichern Freispiele das Zocken. Sie ermöglichen Dir, ohne den Einsatz von echtem Geld und ohne jegliches Risiko an den weltweit beliebtesten Automaten und Slots zu spielen und das Las-Vegas-Feeling ausgiebig zu genießen. Dabei gibt es verschiedene Möglichkeiten, um von kostenlosen Runden zu profitieren. Bei uns erhältst Du Deine Freispiele ganz ohne Einzahlung und an über 200 Slots. Das alles ist natürlich kostenlos und 100 % legal. Lediglich etwas Glück ist notwendig, damit Du von zuhause oder unterwegs in Ruhe die verschiedenen Automatenspiele testen und Dich mit ihren spannenden individuellen Eigenschaften und Spielregeln vertraut machen kannst. Nutze jetzt die Gelegenheit und melde Dich bei uns an, um mehr Gratisdrehs zu erhalten und viele Extra-Goldmünzen zu sammeln.",
    },
  },

  {
    path: "/kneipen-spiele",
    name: "kneipenspiele",
    meta: {
      seoTitle: "Kneipen Spiele",
      seoDescription:
        "Erinnerst Du Dich noch an die besten Kneipenspiele von früher? Diese kannst Du nun auch bequem von zu Hause aus spielen. Egal, welches Genre es Dir seinerzeit angetan hatte, bei uns findest Du viele Spiele, die Dich atmosphärisch sofort in die Kneipen Deiner Jugend zurückversetzen.",
    },
  },

  {
    path: "/spiele-ohne-anmeldung",
    name: "spiele-ohne-anmeldung",
    meta: {
      seoTitle: "Spiele ohne Anmeldung",
      seoDescription:
        "Einfach spielen, ohne dass Du Dir im Vorfeld viele Gedanken machen musst. Genau das bieten wir Dir bei Meine Krone mit unseren kostenlosen Spielen ohne Anmeldung. Wenn Du einmal eingeloggt bist, brauchst Du Dich nicht mehr für jedes Spiel anzumelden, sondern kannst direkt loslegen und so viele verschiedene Slots probieren, wie Du möchtest.",
    },
  },
];
