import * as profileAPI from "@/API/profile.js";
import * as leagueAPI from "@/API/leagues.js";
import { listOpenFriendRequests } from "@/API/friends.js";
import animationEffects from "@/components/animation/animation-effects";

const getDefaultState = () => {
  return {
    user: {},
    userSelectedProfile: null,
    userLeague: {},
    delayedPayload: [],
    delayedBoosterPayload: [],
    delayedUpdate: false,
    delayedBoosterUpdate: false,
    expboostEndDate: null,
    openFriendRequests: [],
    mailReminderCounter: 0,
    newsletterReminderCounter: 0,
    welcomePopupCounter: 0,
    timeDifference: 0,
    rewardsAccepted: [],
    rewardsNewsletter: [],
  };
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  updateWallet(state, payload) {
    if (state.delayedUpdate) {
      //fill payload array when delayed update is activated
      state.delayedPayload.push(payload);
      return;
    }

    switch (payload.type) {
      case "GOLD":
        state.user.gold.amount = payload.amount;
        break;
      case "CROWN":
        state.user.crowns.amount = payload.amount;
        break;
      case "ELIXIR":
        state.user.elixir.amount = payload.amount;
        break;
      default:
        break;
    }
  },
  updateBooster(state, payload) {
    if (state.delayedBoosterUpdate) {
      //fill payload array when delayed update is activated
      state.delayedBoosterPayload.push(payload);
      return;
    }

    //set current payload
    state.expboostEndDate = payload;
  },
  updateLoyalty(state, payload) {
    if (!state.user.loyality) {
      state.user = { ...state.user, loyality: payload };
    } else {
      state.user.loyality = {
        ...state.user.loyality,
        progress: payload.progress,
        level: payload.level,
      };
    }
  },

  updateUserObject(state, user) {
    state.user = user;
  },
  setUserSelectedProfile(state, user) {
    state.userSelectedProfile = user;
  },
  updateUserExperience(state, payload) {
    state.user.experience = payload;
    state.user.level = payload.level;
  },
  setUserLeagueInfos(state, payload) {
    state.userLeague = payload;
  },
  setDelayedUpdate(state, payload) {
    state.delayedUpdate = payload;
  },
  resetDelayedPayload(state) {
    state.delayedPayload = [];
  },
  setDelayedBoosterUpdate(state, payload) {
    state.delayedBoosterUpdate = payload;
    if (payload === false) {
      for (let i = 0; i < state.delayedBoosterPayload.length; i++) {
        state.expboostEndDate = state.delayedBoosterPayload[i];
      }
      state.delayedBoosterPayload = [];
    }
  },
  setExpboostEndDate(state, payload) {
    state.expboostEndDate = payload;
  },
  setFriendRequests: (state, array) => (state.openFriendRequests = array),
  setTimeDifference: (state, payload) => (state.timeDifference = payload),

  increaseMailReminderCounter: (state) => {
    if (state.mailReminderCounter === 3) {
      state.mailReminderCounter = 0;
    } else {
      state.mailReminderCounter += 1;
    }
  },
  increaseNewsletterReminderCounter: (state) => {
    if (state.newsletterReminderCounter === 3) {
      state.newsletterReminderCounter = 0;
    } else {
      state.newsletterReminderCounter += 1;
    }
  },

  increaseWelcomeCounter: (state) => {
    state.welcomePopupCounter += 1;
  },
  setTermsRewards: (state, payload) => {
    state.rewardsAccepted = payload.accepted;
    state.rewardsNewsletter = payload.newsletter;
  },
  clearTermsRewards: (state) => {
    state.rewardsAccepted = [];
    state.rewardsNewsletter = [];
  },
};

const getters = {
  currentUser: (state) => state.user,
  getUserSelectedProfile: (state) => state.userSelectedProfile,
  getUserLeagueInfos: (state) => state.userLeague,
  getDelayedUpdate: (state) => state.delayedUpdate,
  getDelayedPayload: (state) => state.delayedPayload,
  getDelayedBoosterUpdate: (state) => state.delayedBoosterUpdate,
  getTimeDifference: (state) => state.timeDifference,
  getExpboostEndDate: (state) => state.expboostEndDate,
  getUserLoyalty: (state) => {
    if (!state.user.loyality) {
      return {
        level: 0,
        progress: 0,
        loyality: null,
      };
    } else {
      return state.user.loyality;
    }
  },
  isFreshUser: (state) => {
    let today = Date.now();
    let userCreated = new Date(state.user.created_at).getTime();
    return today - userCreated < 86400000;
  },
  isUnconfirmed: (state) => {
    return !state.user.email_verified_at || !state.user.is_verified_account;
  },
  showMailConfirmModal: (state, getters) =>
    getters.isFreshUser &&
    getters.isUnconfirmed &&
    state.mailReminderCounter === 0,

  showMailConfirmReminderModal: (state, getters) =>
    !getters.isFreshUser &&
    getters.isUnconfirmed &&
    state.mailReminderCounter === 0,
  showNewsletterReminderModal: (state, getters) =>
    // !getters.isFreshUser &&
    // getters.isUnconfirmed &&
    state.newsletterReminderCounter === 0,
  showWelcomePopup: (state, getters) => state.welcomePopupCounter <= 1,
};

const actions = {
  resetUserState({ commit }) {
    commit("resetState");
  },

  updateWallet({ commit, getters }, payload) {
    if (getters.getDelayedUpdate) {
      commit("updateWallet", payload);
      return;
    }

    animationEffects.methods.changeWalletNumber(
      payload.type,
      payload.original.amount,
      payload.amount,
      () => {
        commit("updateWallet", payload);
      }
    );
  },

  setDelayedUpdate({ commit, getters }, payload) {
    //nothing changed
    if (getters.getDelayedUpdate === payload) {
      return;
    }
    commit("setDelayedUpdate", payload);

    //delayed update turned off. update from queue
    if (payload === false) {
      const delayedPayload = getters.getDelayedPayload;
      for (let i = 0; i < delayedPayload.length; i++) {
        animationEffects.methods.changeWalletNumber(
          delayedPayload[i].type,
          delayedPayload[i].original.amount,
          delayedPayload[i].amount,
          () => {
            commit("updateWallet", delayedPayload[i]);
          }
        );
      }
      commit("resetDelayedPayload");
    }
  },

  updateUser({ commit }) {
    profileAPI
      .fetchUser()
      .then((res) => {
        this.user = res.data.data.user;
        commit("updateUserObject", res.data.data.user);
        commit(
          "bonusslot/setBonusSlotEndDate",
          res.data.data.bonus_slot_remaining_secs + 1,
          { root: true }
        );
      })
      .catch((e) => console.log(e));
  },
  fetchUserLeagueInfos({ state, commit }) {
    return new Promise((resolve, reject) => {
      leagueAPI
        .fetchUserLeagueById(state.user.id)
        .then((res) => {
          commit("setUserLeagueInfos", res.data.data.league);
          resolve(res);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  checkFriendRequests({ commit }) {
    return new Promise((resolve, reject) => {
      listOpenFriendRequests()
        .then((res) => {
          let noDeletedUsers = res.data.data.inquires.filter(
            (friend) => !friend.deleted_at
          );
          commit("setFriendRequests", noDeletedUsers);
          resolve(noDeletedUsers);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  increaseMailReminderCounter({ commit }) {
    commit("increaseMailReminderCounter");
  },
  increaseNewsletterReminderCounter({ commit }) {
    commit("increaseNewsletterReminderCounter");
  },
  increaseWelcomeCounter({ commit }) {
    commit("increaseWelcomeCounter");
  },
  prepareTermsToAccept({ commit }, termsId) {
    profileAPI
      .getTermsData(termsId)
      .then((res) => {
        commit("setTermsRewards", res.data.data.rewards);
      })
      .catch((e) => console.log(e));
  },
  acceptNewToS({ commit, getters, dispatch, rootGetters }, newsletterBool) {
    return new Promise((resolve, reject) => {
      let termsId = rootGetters.getTermsId;
      if (!termsId) {
        console.log("no terms id defined");
        reject(new Error("No terms ID defined")); // Reject the promise here
        return;
      }
      profileAPI
        .acceptNewToS(termsId, newsletterBool)
        .then((res) => {
          dispatch("updateUser");
          commit("clearTermsRewards");
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
  rejectNewToS({ commit, getters, dispatch, rootGetters }, newsletterBool) {
    return new Promise((resolve, reject) => {
      profileAPI
        .deleteUser()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
